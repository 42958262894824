import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'
import Categories from '../components/categories'


class BlogListTemplate extends React.Component {
  render() {
    const posts = get(this.props, 'data.allContentfulBlogPost')
    const categories = get(this, 'props.data.allContentfulCategory.nodes')

    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
    return (
      <Layout location={this.props.location}>
        <Seo title="Bejegfyzéseim  " />
        <Hero title="Bejegyzésseim " />
        {    console.log(categories)
}
        <Categories categories={categories} />
        <ArticlePreview posts={posts} />
        {!isFirst && (
          <Link to={prevPage} rel="prev">
            ← Previous Page
          </Link>
        )}
        {!isLast && (
          <Link to={nextPage} rel="next">
            Next Page →
          </Link>
        )}
      </Layout>
    )
  }
}

export default BlogListTemplate

export const blogListQuery = graphql`
  query BlogIndexQuery($skip: Int!, $limit: Int!) {
    allContentfulCategory(
      sort: { fields: [order], order: ASC }
      filter: { node_locale: { eq: "hu-HU" } }
    ) {
      nodes {
        id
        name
        slug
        thumbnail {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { node_locale: { eq: "hu-HU" } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        slug
        publishDate(formatString: "YYYY. MM. DD.")
        category {
          name
        }
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          raw
        }
      }
    }
  }
`
